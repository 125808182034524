<template>
  <form @submit.prevent @keyup.enter="login" id="form-wrapper">
    <!-- Email -->
    <div class="item-wrapper">
      <label for="email" class="form-text-indicator">{{
        $t("login_form.email_label") | uppercase
      }}</label>
      <input
        :class="{ redBorder: shouldShowError('email') }"
        @click="clearErrors"
        autocomplete="off"
        v-model.trim="loginForm.email"
        type="email"
        id="email"
      />
      <transition name="fade">
        <div v-if="shouldShowError('email')" class="errorMsg">
          <span class="exclamation">!</span>
          <p>{{ $t(`login_form.${loginError.code}`) | capitalize }}</p>
        </div>
      </transition>
    </div>
    <!-- Password -->
    <div class="item-wrapper">
      <label for="pwd" class="form-text-indicator">{{
        $t("login_form.password_label") | uppercase
      }}</label>
      <input
        :class="{ redBorder: shouldShowError('pwd') }"
        @click="clearErrors"
        v-model.trim="loginForm.pwd"
        type="password"
        id="pwd"
        autocomplete="true"
      />
      <transition name="fade">
        <div v-if="shouldShowError('pwd')" class="errorMsg">
          <span class="exclamation">!</span>
          <p>{{ $t(`login_form.${loginError.code}`) | capitalize }}</p>
        </div>
      </transition>
    </div>
    <div id="login-button" @click="login">
      <p v-if="!performingRequest">
        {{ $t("login_form.submit_button") | uppercase }}
      </p>
      <LoadingDots v-else class="loading" />
    </div>
    <div id="forgot-password" @click="recoverCredentials">
      <p id="recoverCredentialsText">
        {{ $t("login_form.forgot_password_button") | capitalize }}
      </p>
    </div>
    <!--<a
      id="buyTag"
      href="https://zonyx.tech/product/nagi-experience?lang=es"
      target="_blank"
    >
      <p id="buyNagiTagText">
        {{ $t("login_form.buy_text") }}
      </p>
      <button id="buyNagiTagBtn" class="btn default" href="">
        <a
          id="buyTag"
          href="https://zonyx.tech/product/nagi-experience?lang=es"
          target="_blank"
        >
          {{ $t("login_form.buy_button") }}
        </a>
      </button>
    </a>
    -->
  </form>
</template>

<script>
import { loginErrorParser } from "../parsers";

const LoadingDots = () => import("./LoadingDots");
import { mapState } from "vuex";

export default {
  name: "LoginForm",
  components: {
    LoadingDots,
  },
  data() {
    return {
      loginForm: {
        email: "",
        pwd: "",
      },
      recoverModal: false,
    };
  },
  computed: {
    ...mapState({
      loginError: (state) => state.loginError,
      performingRequest: (state) => state.performingRequest,
    }),
  },
  methods: {
    login() {
      if (this.performingRequest) {
        return;
      }
      const user = {
        email: this.loginForm.email,
        pwd: this.loginForm.pwd,
      };
      this.$store.dispatch("signIn", user);
    },
    recoverCredentials() {
      this.$emit("handle-recovery-modal", true);
      this.$store.dispatch("emailSent", false);
    },
    shouldShowError(field) {
      const fCode = this.loginError.code;
      if (field == "common") {
        if (fCode === "auth/network-request-failed") {
          this.$store.dispatch("setCustomError", {
            code: fCode,
            message: this.$t("network-request-failed_error"),
          });
          return true;
        }
        if (fCode === "auth/too-many-requests") {
          this.$store.dispatch("setCustomError", {
            code: fCode,
            message: this.$t("too-many-requests_error"),
          });
          return true;
        }
      }
      return loginErrorParser(field, fCode);
    },
    clearErrors() {
      this.$store.dispatch("clearLoginErrors");
    },
  },
};
</script>

<style scoped>
input:focus {
  outline: none;
}

#form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 16rem;
  position: relative;
}
.item-wrapper {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: white;
  height: 4.125rem;
  position: relative;
}
.item-wrapper:nth-child(2) {
  margin-top: 2rem;
}
.form-text-indicator {
  text-align: left;
  font-weight: 300;
}
input {
  box-sizing: border-box;
  min-height: 2.5rem;
  border: 1px solid gray;
  padding: 2px 10px;
  font-size: 1rem;
}
#login-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-end;
  text-align: center;
  width: 100%;
  height: 2.5rem;
  background-color: #2bace3;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 3rem;
  border-radius: 2px;
  user-select: none;
}
#login-button:hover {
  cursor: pointer;
}
#login-button:active {
  transform: translateY(1px);
  filter: saturate(150%);
}
.loading {
  height: 100%;
  font-size: 2rem;
}
.errorMsg {
  color: white;
  font-size: 0.875rem;
  height: fit-content;
  display: flex;
  margin-top: 0.25rem;
}
.exclamation {
  width: 1rem;
  margin-right: 1rem;
  text-align: center;
  border-radius: 50%;
  border: 1px solid white;
  height: fit-content;
  background-color: red;
}
.errorMsg p {
  margin: 0;
  max-width: 320px;
}
.common {
  position: absolute;
  bottom: -3rem;
}
.common p {
  max-width: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.redBorder {
  border: 2px solid red;
}
#recoverCredentialsText {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
#buyTag {
  display: flex;
  flex-direction: row;

  cursor: pointer;
  color: white;
  text-decoration: none;
  justify-content: space-between !important;
}
#buyNagiTagText {
  margin-right: 25px;
}

@media screen and (max-width: 1024px) {
  #login-button p {
    font-weight: 400;
  }
}

.btn {
  border: 2px solid black;
  background-color: white;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
}

/* Gray */
.default {
  color: white;
  border-color: #e7e7e7;
  border-radius: 6px;
  background-color: transparent;
  background-repeat: no-repeat;
}

.default:hover {
  background: rgba(43, 172, 227, 0.4);
}
</style>
